import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout/Layout'
import styled from 'styled-components'
import { jobOpeningProps } from 'components/Props/current-openings'
import {
  bodyFont,
  themeColor,
  primaryColor,
  white,
  black,
  md,
  sh,
  sm,
  xl,
  lg,
} from 'config/variables'
import SEO from 'components/Seo'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'
import Currentopeningsbanner from 'components/Currentopeningsbanner/Currentopeningsbanner'

const CareerStyles = styled.div`
  #freshteam-widget {
    padding: 0 0 60px;
    background-image: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
    min-height:280px;
    ${xl(`
        padding:70px 0 80px;
    `)}
    ${lg(`
        padding:60px 0 60px;
    `)}
    ${md(`
      padding:40px 0 40px;
    `)}
    .content {
      width: 100%;
      max-width: 1220px;
      padding: 0px 40px;
       margin: 0 auto 0;
     
     ${md(`
           padding: 0px 30px;
    `)}
    ${sm(`
           padding: 0px 20px;
    `)}
    } 
    .hidden-content {
      display: inline-table !important;
    }
    .page-title {
      display: none;
    }
    .job-role-list {
      .role-title {
        padding: 0;
        margin: 0;
        display:flex;
        align-items:flex-end;
        justify-content:space-between;
        margin-bottom: 26px;
        ${md(`
          margin-bottom: 20px;
        `)}
        h5 {
          font-size: 22px;
          font-weight: 500;
          color: #ff4d63;
          font-family: ${bodyFont};
          margin:0px;
          ${md(`
            text-align:left;
            margin:0px
            font-size: 20px;
        `)}
        }
      }
      .role-count {
        margin-right: 0;
        background: transparent;
        font-size: 19px;
        font-weight: 500;
        font-family: ${bodyFont};
        padding: 0;
        ${lg(`
            font-size: 16px;
        `)}
      }
      > ul {
        border: none;
        > li {
          margin-bottom:20px;
          input[type='radio'] {
            display: none;
          }
          border-bottom: none;
          padding: 0;
        }
      }
    }
    .job-list {
      li {
        &.heading {
          transition:all 0.3s;
          ${md(`
              padding:10px 10px 10px 20px;
          `)}
          &:hover {
            background: #dce6fb;
            box-shadow: 0 0 2px 0 rgba(220,230,251,0.2);
          }
          .row {
            display:flex;
            align-items:center;
            justify-content:space-between;
            margin:0px;
          }
          .col-xs-8 {
            width: auto;
            flex: 1 1 auto;
            padding:0px;
          }
          .col-xs-4 {
            width: auto;
            flex:0 0 45px;
            padding:0px;
          }
          .job-title {
            font-weight: 500;
            font-family: ${bodyFont};
            margin-bottom: 5px;
            ${md(`
               font-size:18px;
            `)}
          }
          .location-info {
            display: none;
          }
          .location-icon {
            margin:0px;
          }
          .job-desc {
            color: #3d3d3d;
            font-size: 16px;
            font-family: ${bodyFont};
          }
          .job-location {
            padding: 19px 0;
            border-top:0px;
            ${md(`
               padding: 10px 0;
               margin:0px;
            `)}
            .location-icon {
              display: flex;
              justify-content: flex-end;
              .icon-arrow-right {
                fill: rgba(61, 61, 61, 0.5);
              }
            }
          }
        }
      }
    } 
    .job-details-header {
      background-color:#d7e9ff;
      padding: 28px 0 39px;
      ${md(`
          padding: 30px 0;
      `)}
      .content {
        max-width: 1220px;
        margin: 0 auto;
        padding: 0 40px;
        width:100%;
        ${lg(`
            padding: 0 30px;
        `)}
        ${md(`
          padding: 0 20px;
        `)}
      }
      .back-to-jobs {
          color: ${themeColor};
          font-size: 30px;
          font-weight: bold;
          font-family: ${bodyFont};
          border-bottom: 1px solid rgba(34, 34, 34, 0.1);
          padding:0 0 15px;
          margin-bottom:30px;
          line-height:1;
          transition: all 0.3s;
          ${lg(`
              font-size: 26px;
          `)}
          ${md(`
              font-size: 22px;
              text-align:left;
              padding:0 0 15px;
              margin-bottom:15px;
              display:flex;
              align-items:center;
          `)}
          .icon-arrow-left {
            fill: #3d3d3d;
            margin-right:12px;
            ${md(`
             width:8px;
             margin-top:2px;
          `)}
          }
          &:hover {
            color:#3d3d3d;
          }
      }
      .row {
        display:flex;
        justify-content:space-between;
        ${md(`
             flex-direction: column;
          `)}
        .col-xs-8 {
          flex:1 1 auto;
          ${md(`
              text-align:left;
              padding-bottom:15px;
          `)}
        }
        .brand-color {
          font-size: 28px;
          font-weight: 500;
          line-height:38px;
          font-family: ${bodyFont};
          color:${primaryColor}
          ${lg(`
              font-size: 26px;
              line-height:36px;
          `)}
          ${md(`
              font-size: 22px;
              line-height:32px;
          `)}
        }
        .text-color {
          font-size: 20px;
          font-weight: 500;
          line-height:28px;
          font-family: ${bodyFont};
          color:${primaryColor};
          ${lg(`
              font-size: 18px;
              line-height:26px;
          `)}
          ${md(`
              font-size: 16px;
              line-height:24px;
          `)}
        }
        .col-xs-4 {
          flex:1 1 auto;
          display:flex;
          display: inline-flex;
          align-items: center;
          justify-content: flex-end; 
          ${md(`
            justify-content: flex-start; 
          `)}
          .btn {
            font-size: 18px;
            font-weight: 600;
            font-family: ${bodyFont};
            line-height: 24px;
            letter-spacing: -0.16px;
            text-align: center;
            color: ${white};
            background: ${themeColor};
            border: 1px solid ${themeColor};
            border-radius: 4px;
            padding: 16px 30px 20px;
            position: relative;
            overflow: hidden;
            z-index: 1;
            transition: color 0.15s ease-in-out;
            display: inline-block;
            ${sh(`
              font-size: 16px;
              line-height: 24px;
              padding: 9px 27px 11px;
            `)}
            ${md(`
              font-size: 18px;
              line-height: 26px;
              padding: 8px 24px 10px;
            `)}
            ${sm(`
              font-size: 16px;
              line-height: 22px;
              padding: 9px 20px 10px;
              `)}
            &:hover {
              color: ${black};
            }
            &:after,
            &:before {
              content: '';
              position: absolute;
              width: 101%;
              height: 100%;
              top: 0;
              left: 0;
              background: ${white};
              z-index: -1;
            }
            &:before {
              transform: translateY(-100%);
              transition: transform 15ms ease-in-out;
            }
            &:after {
              transform: translateY(100%);
              transition: transform 0.2s ease-in-out;
            }
            &:hover:before {
              transform: translateY(0);
              transition: transform 0.2s ease-in-out;
            }
            &:hover:after {
              transform: translateY(0);
              transition: transform 0ms ease-in-out;
              transition-delay: 0.2s;
            }
          }
        }
      }
    }
    .job-details-content {
        max-width: 1220px;
        margin: 0 auto;
        padding: 0 40px;
        width:100%;
        ${lg(`
            padding: 0 30px;
        `)}
        ${md(`
          padding: 0 20px;
        `)}
        .left-content {
          padding:46px 0 0;
          ${lg(`
              padding:40px 0 0;
          `)}
          ${md(`
            padding:30px 0 0;
          `)}
          h2, h1, h3, h4, h5, h6 {
            font-size: 21px;
              font-weight: 500;
              font-family: ${bodyFont};
              color:${themeColor};
              margin-bottom:20px;
              display:inline-block;
          }
          p {
            font-size: 16px;
            font-family: ${bodyFont};
            color: ${primaryColor};
            line-height:26px;
            strong {
              font-size: 21px;
              font-weight: 500;
              font-family: ${bodyFont};
              color:${themeColor};
              margin-bottom:10px;
              display:inline-block;
            }
          }
          ul {
            margin-bottom:20px;
            padding-left:20px;
            li {
              font-size: 16px;
            font-family: ${bodyFont};
            color: ${primaryColor};
            line-height:26px;
            }
          }
        }
    }
    &.loading {
      
    }
    .application-form {
      border-radius: 3px;
      margin:0px;
      max-width:100%;
      box-shadow: 3px 0 10px -2px rgba(0,0,0,0.2);
      background:#fff;
      padding:0 10px 50px;
      margin-top:50px;
      .brand-color {
        padding: 0 15px;
        margin-bottom:0px !important;
      }
      .application-btn {
          padding:0 15px;
          margin:10px 0 15px;
        .resume-upload {
          .upload-file  {
            z-index:10;
          }
            margin-top:15px;
        font-size: 19px;
        line-height: 27px;
        letter-spacing: -0.16px;
        text-align: center;
        color: ${black};
        background: ${white};
        border: 1px solid ${themeColor};
        border-radius: 4px;
        padding: 9px 30px 11px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: color 0.15s ease-in-out;
        display: inline-block;
        &:hover {
          .btn-success {
          color: ${white};
          .required {
            color: ${white};  
          }
          }
        }
        .required {
          transition: color 0.15s ease-in-out;
        }
        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 101%;
          height: 100%;
          top: 0;
          left: 0;
          background: ${themeColor};
          z-index: -1;
        }
        &:before {
          transform: translateY(-100%);
          transition: transform 15ms ease-in-out;
        }
        &:after {
          transform: translateY(100%);
          transition: transform 0.2s ease-in-out;
        }
        &:hover:before {
          transform: translateY(0);
          transition: transform 0.2s ease-in-out;
        }
        &:hover:after {
          transform: translateY(0);
          transition: transform 0ms ease-in-out;
          transition-delay: 0.2s;
        }
        .btn-success {
          padding:0px;
          border:none;
          background:transparent;
          color: ${black};
        }
        .error {
          border: 1px solid #d60000 !important;
        }
        }  
      }
      
      .form-group {
        width:50%;
        padding:0 15px;
        float:left;
        &::after, &::before {
          content:'';
          clear: both;
          display:table-cell;
        } 
        
        label {
        color: #b4b4b4;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        letter-spacing: -0.17px;
        text-transform: capitalize;
        cursor: text;
        margin-bottom: 0px;
        margin-left:0px;
      }
        &.link-group {
          width:100%;
          .link-label {
            margin:0px;
            padding: 3px 5px;
          }
          .form-group 
          {
            width:100%;
            padding:0px 0 0 0;
          }
          .btn-add {
            right:15px;
            bottom:15px;
            height:40px;
            border: 1px solid rgba(0,0,0,0.2);
            border-radius: 5px;
            padding:2px 10px 4px;
          }
        }
         .form-control {
          margin:0px;
          width: 100%;
          height: 40px;
          max-width: 100%;
          margin-bottom: 0px;
          padding: 16px 10px 17px 10px;
          border: 1px solid rgba(0,0,0,0.2);
          border-radius: 5px;
          background-color: ${white};
          transition: all 300ms ease;
          color: ${primaryColor};
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.17px;
            -webkit-appearance: none;
            &.error {
              border: 1px solid #d60000 !important;
            }
         }
      }
      .file-field {
        clear: both;
        padding: 0 15px;
        margin-bottom:30px;
        &::after, &::before {
          content:'';
          clear: both;
          display:table-cell;
        } 
        .form-control {
          height:40px;
          padding: 9px 0;
          &.error {
              border: 1px solid #d60000 !important;
            }
        }
      }
      .g-recaptcha {
        width:auto;
      }
      #applicant_submit {
        margin-top:15px;
        font-size: 19px;
        line-height: 27px;
        letter-spacing: -0.16px;
        text-align: center;
        color: ${black};
        background: ${white};
        border: 1px solid ${themeColor};
        border-radius: 4px;
        padding: 9px 30px 11px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        transition: color 0.15s ease-in-out;
        display: inline-block;
        &:hover {
          color: ${white};
        }
        &:after,
        &:before {
          content: '';
          position: absolute;
          width: 101%;
          height: 100%;
          top: 0;
          left: 0;
          background: ${themeColor};
          z-index: -1;
        }
        &:before {
          transform: translateY(-100%);
          transition: transform 15ms ease-in-out;
        }
        &:after {
          transform: translateY(100%);
          transition: transform 0.2s ease-in-out;
        }
        &:hover:before {
          transform: translateY(0);
          transition: transform 0.2s ease-in-out;
        }
        &:hover:after {
          transform: translateY(0);
          transition: transform 0ms ease-in-out;
          transition-delay: 0.2s;
        }
      }
      #job_application_status {
        margin-top:20px;
      }
    }
  }
  .casecontactcardinfo  {
    background:#f8f9ff;
    .contact-info-card {
      .content-part {
        h4 {
          font-size:30px;
          line-height:38px;
          letter-spacing:-0.33px;
          margin-bottom:16px;
        }
        p {
          max-width:532px;
        }
      }
    }
  }
`
const JobOpening = props => {
  const freshteams = useRef()
  const { location } = props

  useEffect(() => {
    if (freshteams.current) {
      let script = document.createElement('script')
      script.src =
        'https://s3.amazonaws.com/files.freshteam.com/production/13065/attachments/3001108845/original/3000006457_widget.js?1580377603'
      document.body.appendChild(script)
    }
  }, [freshteams])
  return (
    <CareerStyles>
      <Layout
        mainClass="careers"
        data={{ showGreatPlaceLogo: true }}
        location={location}
      >
        <SEO
          title="Careers"
          description="Working at Simform is more than just a job. Check out current opportunities at Simform."
          keywords={[`simform`]}
          links={[
            {
              href: 'https://www.simform.com/current-openings/',
              rel: 'canonical',
            },
          ]}
          meta={[
            {
              property: 'og:locale',
              content: 'en_US',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:site_name',
              content: 'Simform',
            },
            {
              property: 'og:url',
              content: 'https://www.simform.com/current-openings/',
            },
            {
              itemProp: 'name',
              content: 'Simform',
            },
            {
              property: 'og:image',
              content: '/banner.png',
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              name: 'twitter:site',
              content: '@Simform',
            },
            {
              name: 'twitter:creator',
              content: '@Simform',
            },
            {
              name: 'twitter:image',
              content: '/banner.png',
            },
          ]}
        />
        <Currentopeningsbanner
          currentOpeningsBannerData={jobOpeningProps}
          {...props}
        />
        <div ref={freshteams} id="freshteam-widget"></div>
        <Casecontactcardinfo Casecontactcardinfodata={jobOpeningProps} />
      </Layout>
    </CareerStyles>
  )
}

export default JobOpening

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/current-openings-banner.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 506) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
