import styled from 'styled-components'
import {
  lg,
  sm,
  md,
  sh,
  primaryColor,
  white,
  themeColor,
  xl,
} from 'config/variables'
import { images } from 'config/images'
export const CurrentopeningsbannerStyles = styled.div`
padding: 110px 0 116px;
background: linear-gradient(to bottom , #ecf5ff, #fff); 
position: relative;
 ${xl(`
      padding: 110px 0 60px; 
  `)}
 ${lg(`
      padding: 90px 0 20px; 
  `)}
 ${md(`
      padding: 60px 0 0;
      margin-bottom:0;
  `)}
 
.container {
    max-width:1220px; 
}
.banner-content-row {
  display:flex;
  justify-content:space-between;
  align-items:center;
   ${lg(`
        flex-direction: column;
  `)}
.banner-content {
    max-width: 589px;
    margin:0 ;
    position: relative;
    z-index:10;
    ${sh(`
        max-width: 500px;
    `)}
    ${xl(`
        max-width: 430px;
    `)}
    ${lg(`
        max-width: 100%;
        order:2;
    `)}
    ${md(`
        max-width: 100%;
    `)}
    .heading-red {
      background-image: linear-gradient(100deg, #3d3d3d, #ff4d63);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    p {
      font-size: 25px;
      letter-spacing: -0.15px;
      line-height: 34px;
      margin-bottom: 38px;
      color: ${primaryColor};
      ${sh(`
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 30px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      `)}
      
    }
    .button  {
        color: ${white};
        background: ${themeColor};
        border: 1px solid ${themeColor};
        &:hover {
            color: ${themeColor};
            background-color:${white};  
        }  
        &:after,
        &:before { 
            background: ${white};
        }
    }
  }
  .img-block {
    max-width:506px;
    width:100%;
    ${lg(`
          margin-bottom:30px;
    `)}
  }
  }
`
