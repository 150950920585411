import React from 'react'
export const jobOpeningProps = {
  currentOpeningsProps: {
    bannerHeading: (
      <>
        Join the <span className="heading-red">Simform family</span>{' '}
      </>
    ),
    bannerDetails: (
      <>
        We are growing very fast and looking for energetic, talented young
        people to join our family. <br /> <br /> You are one step away from a
        next big opportunity. <br /> <br />
        Apply now, because there's never a perfect time to seize the
        opportunity.
      </>
    ),
    BannerAlt: 'Career at Simform',
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: 'Working at Simform is more than just a job',
      para: (
        <>
          We're on a mission to form a family of talented people by nurturing
          the culture of flexibility, openness and work-life balance.
        </>
      ),
      buttonName: 'Learn More',
      buttonLink: '/careers/',
    },
  },
}
