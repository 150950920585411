import React from 'react'
import Image from 'components/ImgOptimized'
import { CurrentopeningsbannerStyles } from './Currentopeningsbanner.styles'
import Button from 'components/Button/Button'

function Currentopeningsbanner(props) {
  const {
    currentOpeningsBannerData: { currentOpeningsProps },
  } = props

  const {
    data: {
      bannerImage: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <CurrentopeningsbannerStyles>
      <div className="container">
        <div className="banner-content-row">
          <div className="banner-content">
            <h1>{currentOpeningsProps.bannerHeading}</h1>
            <p>{currentOpeningsProps.bannerDetails}</p>
            {currentOpeningsProps.buttonName ? (
              <Button
                buttonName={currentOpeningsProps.buttonName}
                buttonLink={currentOpeningsProps.buttonLink}
              />
            ) : (
              ''
            )}
          </div>
          <div className="img-block">
            <Image
              fluid={fluid}
              alt={currentOpeningsProps.BannerAlt}
              lazyload={{
                enabled: false,
              }}
            />
          </div>
        </div>
      </div>
    </CurrentopeningsbannerStyles>
  )
}

export default Currentopeningsbanner
